<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget
			.widget-header
				h1.title KPI Rules
				button.btn.btn-link(type="button", @click="loadData()", v-b-tooltip.hover.left, title="Refresh")
					i.la.la-refresh
			.widget-body
				p.clearfix &nbsp;

				.ov-table-wrapper
					table.table.table-bordered.table-hover.table-finance
						thead
							tr
								th Rule
								th Action
								th Timeframe
								th Last Run
						tbody
							tr(v-if="!records.length")
								td(colspan="4") No matching records were found
							tr(v-for="r in records")
								td
									.d-flex.justify-content-between
										div
											h5 {{ r.name }}
											div {{ r.rule_text }}
											div(v-if="r.min_condition") {{ r.min_condition_text }}
										toggle-button(v-model="r._active", @change="updateField(r, 'active')",
											:labels="{checked: 'Active', unchecked: 'Inactive'}",
											:width="100", :height="26", :font-size="9")
								td
									quick-edit-select(field="action", :r="r", @on-save="updateField", :wrapped="true", :options="actionOptions")
								td
									quick-edit-select(field="timeframe", :r="r", @on-save="updateField", :wrapped="true", :options="timeframeOptions")
								td {{ r.last_run }}
								td.actions
									button.btn.btn-secondary.mr-1(type="button", @click="showResults(r)",
										v-b-tooltip.hover.left, title="Meta Data")
										i.la.la-eye


</template>
<script>
import RuleResultModal from '../views/modals/RuleResultModal';
import Vue from 'vue';

export default {
  name: 'KpiRules',
  async beforeRouteEnter(to, from, next) {
    let ruleId = +to.query.rule_id;
    next((vm) => {
      if (ruleId) {
        vm.showModalId = ruleId;
      }
      return vm;
    });
  },
  data() {
    return {
      busy: false,
      records: [],
      timeframeOptions: [1, 2, 3, 4, 5, 6, 8, 10, 12, 24, 48, 72, 168].map((v) => ({
        v: v,
        t: v % 24 === 0 ? v / 24 + ' Days' : v + ' Hours',
      })),
      actionOptions: [
        { v: 'alert', t: 'Alert' },
        { v: 'pause', t: 'Pause' },
        { v: 'partial_pause', t: 'Partial Block DSP' },
      ],
      showModalId: false,
    };
  },
  methods: {
    async updateField(r, field) {
      let data = {
        id: r.id,
        field,
        val: r['_' + field],
      };
      if (Vue.ovData.rule._booleans.indexOf(field) > -1) {
        data.val = data.val ? 1 : 0;
      }
      try {
        let resp = await this.$ovReq.post('rule/updateField', data);
        r['_edit_' + field] = false;
        r[field] = resp.entity[field];
        this.$ovNotify.success('Rule has been updated');
      } catch (e) {
        this.$ovNotify.error(e);
      }
    },
    async loadData() {
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('rule/getAll');
        this.records = resp.records;
        this.records.forEach((r) => {
          r.active = !!r.active;
          Vue.set(r, '_active', r.active);
        });
        if (this.showModalId) {
          let selected = this.records.find((r) => r.id === this.showModalId);
          if (selected) {
            this.showResults(selected);
            this.showModalId = false;
          }
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
    showResults(r) {
      this.$modal.show(
        RuleResultModal,
        {
          rule: Vue.util.extend({}, r),
        },
        {
          height: 'auto',
          scrollable: true,
        },
      );
    },
  },
  created() {
    this.loadData();
  },
};
</script>
