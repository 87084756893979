<template lang="pug">
	div.default-modal
		loading(:active.sync="busy", :is-full-page="false")
		.modal-header
			.modal-title Edit Settings
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				form(@submit.prevent="submit", v-if="settings")
					.row
						.col-sm-12
							.form-group
								label Key
								input.form-control(type="text", readonly="true", v-model="settings.key")
					.row
						.col-sm-12
							.form-group
								label Description
								input.form-control(type="text", v-model="settings.description")
					.row
						.col-sm-12
							.form-group
								label Value
								textarea.form-control.form-control-sm(v-model="settings.value", rows="4")

					p.clearfix &nbsp;
					.buttons.text-right
						button.btn.btn-primary(type="submit") Save
							i.la.la-fw.la-save
</template>
<script>
import Vue from 'vue';

export default {
  name: 'QuickEditSettingsModal',
  props: ['settings'],
  methods: {
    async submit() {
      if (this.busy) {
        return;
      }
      let data = Vue.util.extend({}, this.settings);
      this.busy = true;
      try {
        await this.$ovReq.post('settings/quickSave', data);
        this.$ovNotify.success('settings has been saved');
        this.$emit('close');
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  data() {
    return {
      busy: false,
    };
  },
};
</script>
