<template lang="pug">
	div.default-modal
		loading(:active.sync="busy", :is-full-page="false")
		.modal-header
			.modal-title "{{ rule.name }}" Rule Meta Data
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				.stats-table-wrapper
					p(v-if="columns.length === 0") No Stats detected
					table.table.table-bordered.tbl-statistics(v-else)
						thead
							tr
								th(v-for="c in columns") {{ c.label }}
						tbody
							tr(v-for="r in rows", v-bind:key="r.id")
								td.c(v-for="c in columns") {{ r[c.name] }}
</template>
<script>
export default {
  name: 'RuleResultModal',
  props: ['rule'],
  data() {
    return {
      busy: false,
      metricHash: {
        passed_impressions: 'Passed Impressions',
        passed_clicks: 'Passed Clicks',
        reject_events: 'Rejected Events',
        installs: 'Installs',
      },
    };
  },
  computed: {
    offer_ids() {
      return Object.keys(this.rule.meta_data);
    },
    columns() {
      let colValues =
        this.offer_ids.length > 0
          ? ['offer_id', ...Object.keys(this.rule.meta_data[this.offer_ids[0]])]
          : [];
      return colValues.map((v) => ({ name: v, label: v }));
    },
    rows() {
      return this.offer_ids.map((id) => ({
        offer_id: id,
        ...this.rule.meta_data[id],
      }));
    },
  },
};
</script>
