<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget
			.widget-header
				h1.title Settings
			.widget-body
				p.clearfix &nbsp;

				.ov-table-wrapper
					table.table.table-bordered.table-hover.table-finance
						thead
							tr
								th Key
								th Description
								th Value
								th Actions
						tbody
							tr(v-if="!records.length")
								td(colspan="4") No matching records were found
							tr(v-for="r in records")
								td {{ r.key }}
								td {{ r.description }}
								td {{ r.value }}
								td.actions
									button.btn.btn-secondary(type="button", @click="editRecord(r)", title="Edit")
										i.la.la-pencil

</template>
<script>
import QuickEditSettingsModal from '../views/modals/QuickEditSettingsModal';
import Vue from 'vue';

export default {
  name: 'Settings',
  data() {
    return {
      busy: false,
      records: [],
    };
  },
  methods: {
    async loadData() {
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('settings/getAll');
        this.records = resp.records;
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
    editRecord(r) {
      this.$modal.show(
        QuickEditSettingsModal,
        {
          settings: Vue.util.extend({}, r),
        },
        {
          height: 'auto',
          scrollable: true,
        },
        {
          'before-close': async (/*event*/) => {
            this.loadData();
          },
        },
      );
    },
  },
  created() {
    this.loadData();
  },
};
</script>
